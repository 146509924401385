import React, { useRef } from "react";
import "./FullScreenModal.scss";

function FullScreenModal({ close, loading, isOpen, children, preventBackgroundClose }) {
  const backgroundRef = useRef();

  const loadingOverlay = loading ? (
    <div className="loading-overlay">
      <div>anw man</div>
    </div>
  ) : null;

  const backgroundClose = e => {
    if (!preventBackgroundClose && e.target === backgroundRef.current) {
      close();
    }
  };

  return (
    <div className={`fullscreen-modal${isOpen ? " open" : ""}`} ref={backgroundRef} onClick={backgroundClose}>
      {children}
      {loadingOverlay}
    </div>
  );
}

export default FullScreenModal;
