import React, { useState } from "react";
import { TextArea } from "frontend-shared/components";
import { BiXCircle } from "react-icons/bi";
import "./NoteCard.scss";

export default function NoteCard({ date, note, id, updateNote, saveNote, deleteNote }) {
  const [deleteButtonVisible, setDeleteButtonVisible] = useState(false);

  const handleUpdate = a => {
    updateNote({ id, note: a.target.value });
  };

  const save = () => {
    saveNote(id, note);
  };

  const remove = () => deleteNote(id);

  return (
    <div className="note-container" onMouseEnter={() => setDeleteButtonVisible(true)} onMouseLeave={() => setDeleteButtonVisible(false)}>
      <BiXCircle className={`delete-button ${deleteButtonVisible ? "visible" : ""}`} onClick={remove} />
      <TextArea
        containerClassName="text-area-container"
        textAreaClassName="text-area"
        characterCounterClassName="character-counter"
        infoTextContainer="info-row-container"
        value={note}
        onChange={handleUpdate}
        infoText={`Last modified: ${date}`}
        height={280}
        onBlur={save}
        maxCharacters={280}
      />
    </div>
  );
}
