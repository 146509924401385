import React, { useState } from "react";
import useModal from "src/hooks/useModal";
import { Button } from "frontend-shared/components";
import { observer } from "mobx-react";
import { BiUser } from "react-icons/bi";
import { EditCardDetailsModal, EditSubscriptionModal, RenewSubscriptionModal, ResubscribeModal } from "./modals";
import { format } from "date-fns";
import { PuffLoader, SyncLoader } from "react-spinners";
import { AppointmentsStore, AppStateStore, AuthStore, ClientsStore, HorsesStore, UserStore } from "src/stores";
import { ASSETS_URL } from "src/config";
import "./Profile.scss";

function Profile() {
  const signOut = () => AuthStore?.signOut();
  const { Modal, openModal: openEditSubscriptionModal } = useModal(EditSubscriptionModal, {}, { preventBackgroundClose: true });
  const { Modal: ResubscribeModalElement, openModal: openResubscribeModal } = useModal(
    ResubscribeModal,
    {},
    { preventBackgroundClose: true }
  );
  const { Modal: RenewModal, openModal: openRenewModal } = useModal(RenewSubscriptionModal);
  const { Modal: EditCardModal, openModal: openEditCardModal } = useModal(EditCardDetailsModal);
  const [cardModalLoading, setCardModalLoading] = useState(false);

  const { loading, user, upcomingInvoiceAmount } = UserStore || {};
  const { firstName, lastName, email, address, id, planType, planEndDate, planIsCancelling, planIsTrialing, planIsPastDue, cardDetails } =
    user;
  const { street, city, state, zip } = address || {};

  const endTimeText = format(planEndDate || 0, "MMM dd, yyyy");
  const toggleRenewModal = () => openRenewModal({ endTimeText: endTimeText });

  const editSubscription = () => openEditSubscriptionModal({ cardDetails });
  const resubscribe = () => openResubscribeModal({ cardDetails });

  const openCardDetailsModal = async () => {
    setCardModalLoading(true);
    const secret = await AppStateStore?.fetchSetupIntentSecret();
    openEditCardModal({ secret: secret, cardDetails, planIsPastDue });
    setCardModalLoading(false);
  };

  const clientCount = ClientsStore?.clients?.length || 0;
  const horseCount = HorsesStore?.horses?.length || 0;
  const appointmentCount = AppointmentsStore?.appointments?.length || 0;

  const logoURI = `https://${ASSETS_URL}/${id}/logo.png?v=${new Date().valueOf().toString()}`;

  const [pictureError, setPictureError] = useState(false);
  const markPictureError = () => {
    setPictureError(true);
  };

  if (loading) {
    return (
      <div className="profile profile-loading">
        <PuffLoader />
      </div>
    );
  }

  const pictureComponent = pictureError ? (
    <div className="picture placeholder">
      <BiUser size={108} />
    </div>
  ) : (
    <img className="picture" alt="My profile." src={logoURI} onError={markPictureError} />
  );

  const planPrice = (upcomingInvoiceAmount / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });

  const planText =
    planType === "canceled"
      ? "Your plan has been canceled."
      : planIsCancelling
      ? planIsTrialing
        ? `After your free trial ends on ${endTimeText}, this plan will no longer be available.`
        : `Your plan will be canceled on ${endTimeText}.`
      : planIsTrialing
      ? `After your free trial ends on ${endTimeText}, your plan will renew for ${planPrice}.`
      : `Your plan renews on ${endTimeText}${planPrice !== "$NaN" ? ` for ${planPrice}` : ""}.`;

  const cardModalDetails = cardModalLoading ? <SyncLoader size={8} speedMultiplier={0.8} color={"var(--primary)"} /> : "Edit card details";

  return (
    <div className="profile">
      <div className="title-row">
        <div className="title">My Profile</div>
      </div>
      <div className="content">
        <div className="picture-row">
          {pictureComponent}
          <div className="primary-info">
            <div className="name">
              {firstName} {lastName}
            </div>
            {planIsPastDue ? (
              <div className="error">Your latest invoice is past due. To prevent subscription cancellation, update your card.</div>
            ) : null}
            <div className="email">{email}</div>
            <div className="email">{planType.toTitleCase()} plan</div>
            <div className="email">{planText}</div>
            <div className="buttons-row">
              {planType.toLowerCase() === "canceled" ? (
                <Button action={resubscribe}>Resubscribe</Button>
              ) : planIsCancelling ? (
                <Button action={toggleRenewModal}>Renew subscription</Button>
              ) : (
                <Button action={editSubscription}>Edit subscription</Button>
              )}
              <Button action={openCardDetailsModal} disabled={cardModalLoading}>
                {cardModalDetails}
              </Button>
              <Button action={signOut} type="cancel">
                Sign Out
              </Button>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="info-section">
            <div className="header">Personal Information</div>
            <div className="info-row">
              <div className="info-title">Name</div>
              <div className="info-value">
                {firstName} {lastName}
              </div>
            </div>
            <div className="info-row">
              <div className="info-title">Email Address</div>
              <div className="info-value">{email}</div>
            </div>

            <div className="info-row">
              <div className="info-title">Address</div>
              <div className="info-value">{street}</div>
            </div>
            <div className="info-row">
              <div className="info-title"></div>
              <div className="info-value">
                {city}, {state} {zip}
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="info-section">
            <div className="header">Account Information</div>
            <div className="info-row">
              <div className="info-title">Clients</div>
              <div className="info-value">
                {clientCount} Client{clientCount !== 1 ? "s" : ""}
              </div>
            </div>
            <div className="info-row">
              <div className="info-title">Horses</div>
              <div className="info-value">
                {horseCount} Horse{horseCount !== 1 ? "s" : ""}
              </div>
            </div>
            <div className="info-row">
              <div className="info-title">Appointments</div>
              <div className="info-value">
                {appointmentCount} Appointment{appointmentCount !== 1 ? "s" : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <ResubscribeModalElement />
      <RenewModal />
      <EditCardModal />
    </div>
  );
}

export default observer(Profile);
