import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "frontend-shared/components";
import { observer } from "mobx-react";
import { PuffLoader } from "react-spinners";
import ClientsStore from "src/stores/ClientsStore";
import "./NewClientModal.scss";

function NewClientModal({ close, clientId }) {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState("");
  const updateName = ({ target }) => setName(target.value);
  const isValid = name;

  const cancelButtonStyles = uploading ? { opacity: 0.1, pointerEvents: "none" } : {};
  const actionButtonStyles = isValid ? (uploading ? { opacity: 0.3, pointerEvents: "none" } : {}) : { opacity: 0.3, pointerEvents: "none" };

  const onSubmit = async () => {
    if (isValid && !uploading) {
      setUploading(true);
      const clientId = await ClientsStore?.addClient({ name: name });
      setUploading(false);
      navigate(`/clients/${clientId}`);

      close();
    }
  };

  const actionButtonContent = uploading ? <PuffLoader loading className="loader" size={24} /> : "Create Client";

  return (
    <div className="new-client-modal-container">
      <div className="content">
        <div className="title">Create New Client</div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Name</div>
              <Input placeholder="John Doe" value={name} onChange={updateName} />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button style={cancelButtonStyles} action={close}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" style={actionButtonStyles} action={onSubmit}>
              {actionButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(NewClientModal);
