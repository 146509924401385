import { autorun, makeAutoObservable } from "mobx";
import AuthStore from "./AuthStore";
import AppStateStore from "./AppStateStore";
import { request } from "frontend-shared/utils";
class UserStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore.authenticated) {
        this.initialize();
      } else {
        this.clear();
      }
    });
  }

  loading = false;
  user = {};
  shouldEditsBeAllowed = true;

  upcomingInvoiceAmount = 0;

  initialize() {
    this.fetchUser();
  }

  async fetchUpcomingInvoiceAmount() {
    if (!this.user.planType || this.user.planType === "canceled") return;
    const result = await request.get(`/v1/users/me/previewChange/${this.user.planType.toUpperCase()}`);
    this.upcomingInvoiceAmount = result.amount_due;
  }

  async fetchUser() {
    this.loading = true;
    try {
      const user = await request.get("/v1/users/me");
      this.user = user;
      this.shouldEditsBeAllowed = user.planType !== "canceled";
      await this.fetchUpcomingInvoiceAmount();
    } catch (err) {
      console.warn(err);
    } finally {
      this.loading = false;
    }
  }

  async updateUserTheme(theme) {
    try {
      AppStateStore.setLoading(true);
      const updatedUser = await request.post("/v1/theme", { body: { theme } });
      this.user = updatedUser;
    } catch (err) {
      console.warn(err);
    } finally {
      AppStateStore.setLoading(false);
    }
  }

  async updateUserTerms(terms) {
    try {
      AppStateStore.setLoading(true);
      const updatedUser = await request.post("/v1/users/me/terms", { body: { terms } });
      this.user = updatedUser;
    } catch (err) {
      console.warn(err);
    } finally {
      AppStateStore.setLoading(false);
    }
  }

  async updateNotificationSettings(notificationTime) {
    try {
      const updatedUser = await request.post("/v1/users/me/notifications", {
        body: { notificationTime: notificationTime }
      });
      this.user = updatedUser;
    } catch (err) {
      console.warn(err);
    }
  }

  async updateVehicleSettings(updates) {
    try {
      AppStateStore.setLoading(true);
      const updatedUser = await request.post("/v1/users/me/settings", {
        body: { updates }
      });
      this.user = updatedUser;
    } catch (err) {
      console.warn(err);
    } finally {
      AppStateStore.setLoading(false);
    }
  }

  async updateAllowOverlappingAppointments(allowOverlappingAppointments) {
    try {
      const updatedUser = await request.post("/v1/users/me/settings", {
        body: { updates: { allowOverlappingAppointments } }
      });
      this.user = updatedUser;
    } catch (err) {
      console.warn(err);
    }
  }

  setLoading(loading) {
    this.loading = loading;
  }

  clear() {
    this.user = {};
  }
}

export default new UserStore();
