import React from "react";
import "./TextArea.scss";

const TextArea = ({
  height = 250,
  placeholder,
  value,
  onChange,
  maxCharacters,
  error,
  disabled,
  infoTextContainer,
  infoText,
  onBlur,
  textAreaClassName,
  containerClassName,
  characterCounterClassName
}) => {
  const errorMessage = error ? <p className="input-error">{error}</p> : null;
  const infoMessage = infoText ? <p className="info-text">{infoText}</p> : null;
  const charCounter = maxCharacters ? (
    <p className={`input-character-count ${characterCounterClassName ? characterCounterClassName : ""}`}>
      {value.length} / {maxCharacters}
    </p>
  ) : null;

  const errorIcon = error ? (
    <svg className="input-error-icon" viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#E0614A" />
      <line x1="5.5" y1="5.5" x2="10.5" y2="10.5" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="10.5" y1="5.5" x2="5.5" y2="10.5" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ) : null;

  const errorStyles = error
    ? {
        borderRight: "6px solid #E0614A"
      }
    : {};
  return (
    <div className={`textarea${disabled ? " disabled" : ""} ${containerClassName ? containerClassName : ""}`}>
      <textarea
        style={{ ...errorStyles, height }}
        className={textAreaClassName}
        maxLength={maxCharacters}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange || (() => null)}
        onBlur={onBlur || (() => null)}
      />
      {errorIcon}
      <div
        className={infoTextContainer ? infoTextContainer : ""}
        style={{ width: "100%", flexDirection: "row", display: "flex", justifyContent: "space-between" }}
      >
        {errorMessage}
        {infoMessage}
        {charCounter}
      </div>
    </div>
  );
};

export default TextArea;
