import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Input, Select } from "frontend-shared/components";
import { breeds } from "src/constants";
import { PuffLoader } from "react-spinners";
import { HorsesStore, UserStore } from "src/stores";
import "./EditHorseModal.scss";
import { useNavigate } from "react-router-dom";

function EditHorseModal({ close, clientId, horseName, horseAge, horseBreed, horseColor, id }) {
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  const [name, setName] = useState(horseName || "");
  const updateName = ({ target }) => setName(target.value);
  const [age, setAge] = useState(horseAge || "");
  const updateAge = ({ target }) => setAge(target.value);
  const [breed, setBreed] = useState(horseBreed || null);
  const [color, setColor] = useState(horseColor || "");
  const updateColor = ({ target }) => setColor(target.value);
  const isValid = name && age && breed && color;

  const cancelButtonStyles = uploading ? { opacity: 0.1, pointerEvents: "none" } : {};
  const actionButtonStyles = isValid ? (uploading ? { opacity: 0.3, pointerEvents: "none" } : {}) : { opacity: 0.3, pointerEvents: "none" };

  const onSubmit = async () => {
    if (isValid && !uploading) {
      setUploading(true);
      await HorsesStore?.updateHorse({ id, name, color, age, breed, clientId });
      close();
    }
  };

  const deleteHorse = async () => {
    if (!uploading) {
      setUploading(true);
      navigate(`/clients/${clientId}`);
      await HorsesStore?.deleteHorse(id);
      close();
    }
  };

  const actionButtonContent = uploading ? <PuffLoader loading className="loader" size={24} /> : "Edit Horse";

  return (
    <div className="edit-horse-modal-container">
      <div className="content">
        <div className="title">Edit Horse</div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Name</div>
              <Input placeholder="Name" value={name} onChange={updateName} />
            </div>
            <div className="input-container">
              <div className="input-title">Breed</div>
              <Select options={breeds} value={breed} onChange={setBreed} isSearchable placeholder="Select Breed" />
            </div>
            <div className="input-container">
              <div className="input-title">Age</div>
              <Input placeholder="Age" value={age} onChange={updateAge} />
            </div>
            <div className="input-container">
              <div className="input-title">Color</div>
              <Input placeholder="Color" value={color} onChange={updateColor} />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button style={cancelButtonStyles} action={close}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button className="delete-button" style={cancelButtonStyles} disabled={!UserStore?.shouldEditsBeAllowed} action={deleteHorse}>
              Delete
            </Button>
            <Button type="primary" style={actionButtonStyles} action={onSubmit}>
              {actionButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(EditHorseModal);
