import React from "react";
import { observer } from "mobx-react";
import ClientsStore from "src/stores/ClientsStore";
import AppointmentsStore from "src/stores/AppointmentsStore";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import "./Clients.scss";
import HorsesStore from "src/stores/HorsesStore";
import useModal from "src/hooks/useModal";
import NewClientModal from "./NewClientModal";
import { UserStore } from "src/stores";

function ClientRow({ c }) {
  const navigate = useNavigate();
  let appointments = AppointmentsStore?.appointmentsForClient(c.id);

  let locationsText = c.locations?.length ? `${c.locations.length.toWords()} locations` : "(no locations)";
  if (c.locations?.length === 1) {
    locationsText = `One location`;
  }

  let upcomingAppointments = appointments?.filter(a => new Date(a.start) > new Date()) || [];
  let hasNextAppointment = upcomingAppointments.length > 0;
  const nextAppointmentObject = hasNextAppointment && upcomingAppointments.sort((a, b) => a.start > b.start)[0];
  const nextAppointment = hasNextAppointment && format(parseISO(nextAppointmentObject.start), "E, MMM do 'at' h:mm a");

  const goToClient = () => navigate("/clients/" + c.id);

  let contactMethodsLabel = "(none)";
  if (c?.phone && c?.email) contactMethodsLabel = "Phone, Email";
  else if (c?.phone) contactMethodsLabel = "Phone Only";
  else if (c?.email) contactMethodsLabel = "Email Only";
  return (
    <tr className="hover" onClick={goToClient}>
      <td>{c.name}</td>
      <td>{c.horses?.length || "0"} Horses</td>
      <td>{locationsText}</td>
      <td>{appointments?.length > 0 ? `${appointments?.length} Appointments` : "(none)"}</td>
      <td>{hasNextAppointment ? nextAppointment : "(none)"}</td>
      <td>{contactMethodsLabel}</td>
    </tr>
  );
}

function Clients() {
  const { openModal: createNewClient, Modal: NewClientModalComponent } = useModal(NewClientModal);

  const ClientRows = ClientsStore?.getClientsWithHorses?.map(c => <ClientRow key={c.id} c={c} />) || null;

  const newClientButton = UserStore?.shouldEditsBeAllowed ? (
    <div className="action-text" onClick={createNewClient}>
      Add New
    </div>
  ) : null;

  return (
    <div className="clients-container">
      <div className="header">
        <div className="name-contact-container">
          <div className="name">
            <div>Clients</div>
          </div>
        </div>
        <div className="stats">
          <div className="appointments">
            <div>
              {ClientRows.length} Client{ClientRows.length !== 1 ? "s" : ""}
            </div>
            <div>
              {HorsesStore?.horses.length} Horse{HorsesStore?.horses.length !== 1 ? "s" : ""}
            </div>
          </div>
          {newClientButton}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th># of Horses</th>
            <th>Location</th>
            <th># of Appointments</th>
            <th>Upcoming Appointment</th>
            <th>Contact Methods</th>
          </tr>
        </thead>
        <tbody>{ClientRows}</tbody>
      </table>
      <NewClientModalComponent />
    </div>
  );
}

export default observer(Clients);
