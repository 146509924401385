import React from "react";
import { observer } from "mobx-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { Redirect } from "frontend-shared/components";
import Auth from "src/components/@Auth";
import Calendar from "src/components/@Calendar";
import Dashboard from "src/components/@Dashboard";
import Sidebar from "src/components/Sidebar";
import Clients from "src/components/@Clients";
import ClientDetails from "src/components/@ClientDetails";
import HorseDetails from "src/components/@HorseDetails";
import Profile from "src/components/Profile";
import AppointmentsStore from "src/stores/AppointmentsStore";
import { AuthStore, ClientsStore, HorsesStore, AppStateStore, UserStore } from "src/stores";
import "./App.scss";

const Main = observer(() => {
  return (
    <div className="main-content">
      <Sidebar />
      <Routes>
        <Route element={<Dashboard />} path="/dashboard" />
        <Route element={<Clients />} path="/clients" />
        <Route element={<ClientDetails />} path="/clients/:clientId" />
        <Route element={<HorseDetails />} path="/clients/:clientId/horse/:horseId" />
        <Route element={<Calendar />} path="/calendar" />
        <Route element={<Profile />} path="/account" />
        <Route element={<Redirect to="/dashboard" />} path="*" />
      </Routes>
    </div>
  );
});

const App = observer(() => {
  const { authenticated, authDetermined } = AuthStore || {};

  const loading =
    AppointmentsStore?.loading || ClientsStore?.loading || HorsesStore?.loading || AppStateStore?.loading || UserStore?.loading;

  if (!authDetermined || loading) {
    return (
      <div className="loader-container">
        <PuffLoader loading className="loader" size={80} />
      </div>
    );
  }

  if (authenticated) return <Main />;

  return <Auth />;
});

function ProvidersWrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default ProvidersWrappedApp;
