import React, { useState } from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { UserStore } from "src/stores";
import "./CollapsingSection.scss";

const CollapsingSection = ({ actionText, action, title, children, ...props }) => {
  const [visible, setVisible] = useState(true);
  const toggleVisible = () => setVisible(!visible);

  const runAction = e => {
    e.stopPropagation();
    setVisible(true);
    if (action) action();
  };

  const actionComponent = UserStore?.shouldEditsBeAllowed ? (
    <div className="action" onClick={runAction}>
      {actionText}
    </div>
  ) : null;

  return (
    <div className="collapsing-section">
      <div className={`header-row${visible ? " visible" : ""}`} onClick={toggleVisible}>
        <div className="collapsing-section-header">{title}</div>
        <div className="actions-container">
          {actionComponent}
          {visible ? <BiChevronDown size={36} /> : <BiChevronRight size={36} />}
        </div>
      </div>
      {visible ? children : null}
    </div>
  );
};

export default CollapsingSection;
