import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Select } from "frontend-shared/components";
import { request } from "frontend-shared/utils";
import { AuthStore, AppStateStore, UserStore } from "src/stores";
import { SyncLoader } from "react-spinners";
import "./ResubscribeModal.scss";

const options = ["Farrier Plus Premium at $20/Month", "Farrier Plus Standard at $15/Month", "Farrier Plus Basic at $10/Month"];

function BenefitsExplanation({ from, to }) {
  const features = (
    <>
      <div className="feature">- Access to the app</div>
      {to !== "BASIC" ? <div className="feature">- Terms and Conditions page</div> : null}
      {to !== "BASIC" ? <div className="feature">- Push Notification Reminders</div> : null}
      {to === "PREMIUM" ? <div className="feature">- Automatic reminders for clients</div> : null}
      {to === "PREMIUM" ? <div className="feature">- In-App Square Payments</div> : null}
    </>
  );

  return (
    <>
      <div className="explanation">Selecting the {to.toTitleCase()} plan will provide the following features:</div>
      <div className="feature-list">{features}</div>
    </>
  );
}

function EditSubscriptionModal({ close, cardDetails }) {
  const [selectedPlan, setSelectedPlan] = useState("Farrier Plus Basic at $10/Month");
  const selectedPlanAsPlan = selectedPlan.split(" ")[2]?.toUpperCase() || "";

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const explainationContent = <BenefitsExplanation to={selectedPlanAsPlan} />;

  const onSubmit = async () => {
    setLoading(true);
    const result = await request.post("/v1/users/me/resubscribe", { body: { plan: selectedPlanAsPlan } });
    if (result?.error) {
      setLoading(false);
      setError(result.error);
      return;
    }
    setLoading(false);
    AppStateStore?.setLoading(true);
    setTimeout(async () => {
      await AuthStore.refreshUserSession();
      await UserStore?.fetchUser();
      AppStateStore?.setLoading(false);
      close();
    }, 7500);
    // const newPlan = selectedPlan.split(" ")[2].toUpperCase();

    // close();
  };

  const errorComponent = error ? <div className="error">{error}</div> : null;
  const submitButtonContent = loading ? <SyncLoader color={"var(--white)"} size={8} /> : "Update Subscription";

  return (
    <div className="resubscribe-modal-container">
      <div className="content">
        <div className="title-container">
          <div className="title">Resubscribe</div>
        </div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Connected Card</div>
              <div className="input-title">
                {cardDetails?.brand?.toTitleCase()} •••• {cardDetails?.last4} Expires {cardDetails?.month?.toString().padStart(2, "0")}/
                {cardDetails?.year}
              </div>
            </div>
            <div className="input-container">
              <div className="input-title">Selected subscription</div>
              <Select value={selectedPlan} onChange={setSelectedPlan} hideSelectedOptions={true} options={options} />
            </div>
            <div className="input-container">{explainationContent}</div>
            {errorComponent}
          </div>
        </div>
        <div className="bottom-buttons">
          <Button type="cancel" action={close} disabled={loading}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" disabled={loading} action={onSubmit}>
              {submitButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(EditSubscriptionModal);
