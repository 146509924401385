export const IS_DEV = false;

export const breeds = [
  "Akhal-Teke",
  "American Saddlebred",
  "Andalusian",
  "Appaloosa",
  "Arabian",
  "Ardennais",
  "Belgian",
  "Belgian Warmblood",
  "Black Forest",
  "Breton",
  "Clydesdale",
  "Cob",
  "Criollo",
  "Curly",
  "Dutch Warmblood",
  "Falabella",
  "Fjord",
  "Friesian",
  "Galineers Cob",
  "Haflinger",
  "Hanoverian",
  "Holstein",
  "Hucul Pony",
  "Icelandic",
  "Irish Draught",
  "Irish Sport",
  "Knabstrupper",
  "Konik",
  "Lipizzan",
  "Lusitano",
  "Mangalarga Marchador",
  "Marwari",
  "Missouri Fox Trotter",
  "Mongolian",
  "Morgan",
  "Mustang",
  "Noriker",
  "Oldenburg",
  "Paint",
  "Paso Fino",
  "Percheron",
  "Peruvian Paso",
  "Pure Spanish Breed",
  "Quarter Horse",
  "Shetland Pony",
  "Shire",
  "Silesian",
  "Standardbred",
  "Tennessee Walking Horse",
  "Thoroughbred",
  "Trakehner"
];

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];
