import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { planPrices, request } from "frontend-shared/utils";
import { Button, Select } from "frontend-shared/components";
import { SyncLoader } from "react-spinners";
import "./EditSubscriptionModal.scss";
import UserStore from "src/stores/UserStore";
import { format } from "date-fns";
import { AuthStore } from "src/stores";
import AppStateStore from "src/stores/AppStateStore";

const options = ["Farrier Plus Premium at $20/Month", "Farrier Plus Standard at $15/Month", "Farrier Plus Basic at $10/Month", "Cancel"];

const getUpgradeOrDowngrade = (currentPlan, newPlan) => {
  //true = upg, false = downg
  if (currentPlan === "PREMIUM") return false;
  if (currentPlan === "STANDARD" && newPlan === "BASIC") return false;
  return true;
};

function DowngradeExplanation({ from, to }) {
  const features = (
    <>
      {to === "basic" ? <div className="feature">- Terms and Conditions page</div> : null}
      {to === "basic" ? <div className="feature">- Push Notification Reminders</div> : null}
      {to !== "premium" && from === "premium" ? <div className="feature">- Automatic reminders for clients</div> : null}
      {to !== "premium" && from === "premium" ? <div className="feature">- In-App Square Payments</div> : null}
    </>
  );

  return (
    <>
      <div className="explanation">Downgrading your plan to {to} will remove the following features:</div>
      <div className="feature-list">{features}</div>
    </>
  );
}

function UpgradeExplanation({ from, to }) {
  const features = (
    <>
      {from !== "standard" ? <div className="feature">- Terms and Conditions page</div> : null}
      {from !== "standard" ? <div className="feature">- Push Notification Reminders</div> : null}
      {to === "premium" ? <div className="feature">- Automatic reminders for clients</div> : null}
      {to === "premium" ? <div className="feature">- In-App Square Payments</div> : null}
    </>
  );

  return (
    <>
      <div className="explanation">Upgrading your plan to {to} will grant the following features:</div>
      <div className="feature-list">{features}</div>
    </>
  );
}

function CancelExplanation({ paidUntil }) {
  const features = (
    <>
      <div className="feature">- You will still be able to use the app until {format(new Date(paidUntil), "MMM do, yyyy")}.</div>
      <div className="feature">- After that, you will be able to view your data.</div>
      <div className="feature">- To delete your data, please request account deletion.</div>
    </>
  );

  return (
    <>
      <div className="explanation">Cancelling your plan will mean:</div>
      <div className="feature-list">{features}</div>
    </>
  );
}

function EditSubscriptionModal({ close, cardDetails }) {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [prorationAmounts, setProrationAmounts] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const currentPlan = UserStore?.user?.planType;
  const currentSelectedPlan = options.find(a => a.toLowerCase().includes(currentPlan.toLowerCase()));

  useEffect(() => {
    setSelectedPlan(options.find(a => a.toLowerCase().includes(currentPlan.toLowerCase())));
  }, [currentPlan, setSelectedPlan]);

  useEffect(() => {
    const plans = Object.keys(planPrices).filter(p => p.toUpperCase() !== UserStore?.user?.planType.toUpperCase());
    (async () => {
      const [first, second] = await Promise.all(
        plans.map(
          async plan => {
            const result = await request.get(`/v1/users/me/previewChange/${plan.toUpperCase()}`);
            if (result.amount_due) {
              return { [plan.toUpperCase()]: result.amount_due };
            } else {
              return { [plan.toUpperCase()]: result.ending_balance };
            }
          },
          [setProrationAmounts]
        )
      );
      setProrationAmounts({ ...first, ...second });
    })();
  }, [setProrationAmounts]);

  const selectedPlanAsPlan = selectedPlan.split(" ")[2]?.toUpperCase() || "";
  const isValid = selectedPlanAsPlan !== currentPlan && !loading && !!Object.keys(prorationAmounts).length;

  const selectStyles = {
    option: (provided, state) => {
      if (state.data.value === "Cancel") {
        return { ...provided, color: "red" };
      } else if (state.data.value.toLowerCase().includes(currentPlan?.toLowerCase())) {
        return { ...provided, color: "var(--primary)" };
      } else {
        return { ...provided };
      }
    },
    singleValue: (provided, state) => {
      if (state.data.value === "Cancel") {
        return { ...provided, color: "red" };
      } else if (state.data.value.toLowerCase().includes(currentPlan?.toLowerCase())) {
        return { ...provided, color: "var(--primary)" };
      } else {
        return { ...provided };
      }
    }
  };
  const explainationContent =
    selectedPlanAsPlan === currentPlan ? null : selectedPlan === "Cancel" ? (
      <CancelExplanation paidUntil={UserStore?.user?.planEndDate} />
    ) : getUpgradeOrDowngrade(currentPlan, selectedPlanAsPlan) ? (
      <UpgradeExplanation to={selectedPlanAsPlan.toLowerCase()} from={currentPlan.toLowerCase()} />
    ) : (
      <DowngradeExplanation to={selectedPlanAsPlan.toLowerCase()} from={currentPlan.toLowerCase()} />
    );

  const actionButtonStyles = isValid ? {} : { opacity: 0.3, pointerEvents: "none" };

  const onSubmit = async () => {
    if (selectedPlan === "Cancel") {
      AppStateStore?.setLoading(true);
      await request.put("/v1/users/me/subscription", { body: { canceled: true } });
    } else {
      const newPlan = selectedPlanAsPlan;
      if (newPlan === currentPlan) return;
      setLoading(true);
      const result = await request.put("/v1/users/me/subscription", { body: { newPlan } });
      if (result?.error) {
        setLoading(false);
        setError(result.error);
        return;
      }
    }
    setLoading(false);
    AppStateStore?.setLoading(true);
    setTimeout(async () => {
      await AuthStore.refreshUserSession();
      await UserStore?.fetchUser();
      AppStateStore?.setLoading(false);
      close();
    }, 6500);
  };

  const prorationsLoaded = !!Object.keys(prorationAmounts).length;
  const prorationAmount = prorationAmounts[selectedPlanAsPlan];
  const prorationString =
    prorationAmount != null
      ? (Math.abs(prorationAmount) / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        })
      : "";

  const errorComponent = error ? <div className="error">{error}</div> : null;
  const submitButtonContent = loading ? <SyncLoader color={"var(--white)"} size={8} /> : "Update Subscription";

  return (
    <div className="edit-subscription-modal-container">
      <div className="content">
        <div className="title-container">
          <div className="title">Edit Subscription</div>
        </div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Connected Card</div>
              <div className="input-title">
                {cardDetails?.brand?.toTitleCase()} •••• {cardDetails?.last4} Expires {cardDetails?.month?.toString().padStart(2, "0")}/
                {cardDetails?.year}
              </div>
            </div>
            <div className="input-container">
              <div className="input-title">Current plan</div>
              <div className="input-title">{currentSelectedPlan}</div>
            </div>
            <div className="input-container">
              <div className="input-title">Change subscription to</div>
              {/* <Input placeholder="Street" value={""} onChange={() => null} /> */}
              <Select
                styles={selectStyles}
                value={selectedPlan}
                onChange={setSelectedPlan}
                hideSelectedOptions={true}
                options={[
                  "Farrier Plus Premium at $20/Month",
                  "Farrier Plus Standard at $15/Month",
                  "Farrier Plus Basic at $10/Month",
                  "Cancel"
                ]}
              />
            </div>
            <div className="input-container">{explainationContent}</div>
            <div className="input-container">
              <div className="input-title">
                {prorationsLoaded ? (
                  prorationAmount != null ? (
                    prorationAmount < 0 ? (
                      getUpgradeOrDowngrade(currentPlan, selectedPlanAsPlan) ? (
                        `Updating will change your credit to ${prorationString} for future invoices.`
                      ) : (
                        `Updating will credit you ${prorationString} towards future invoices.`
                      )
                    ) : (
                      `Updating will charge your card ${prorationString}.`
                    )
                  ) : null
                ) : (
                  <div className="protation-loader-container">
                    <SyncLoader color="var(--primary)" size={8} />
                  </div>
                )}
              </div>
            </div>
            {errorComponent}
          </div>
        </div>
        <div className="bottom-buttons">
          <Button type="cancel" action={close} disabled={loading}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" disabled={!isValid} style={actionButtonStyles} action={onSubmit}>
              {submitButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(EditSubscriptionModal);
