import React from "react";
import { observer } from "mobx-react";
import { Button } from "frontend-shared/components";
import { planPrices, request } from "frontend-shared/utils";
import { AuthStore, AppStateStore, UserStore } from "src/stores";
import "./RenewSubscriptionModal.scss";

function RenewSubscriptionModal({ close, endTimeText }) {
  const currentPlan = UserStore?.user?.planType;
  const onSubmit = async () => {
    AppStateStore?.setLoading(true);
    await request.put("/v1/users/me/subscription", { body: { renew: true } });
    setTimeout(async () => {
      await AuthStore.refreshUserSession();
      await UserStore?.fetchUser();
      AppStateStore?.setLoading(false);
      close();
    }, 6500);
  };

  const planPrice = (planPrices[currentPlan] / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    })
    .split(".")[0];

  return (
    <div className="renew-subscription-modal-container">
      <div className="content">
        <div className="title-container">
          <div className="title">Renew Subscription</div>
        </div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Current plan</div>
              <div className="input-title">
                Farrier Plus {currentPlan.toTitleCase()} at {planPrice}/Month
              </div>
            </div>
            <div className="input-container">
              <div className="input-title">This plan will no longer be canceled.</div>
              <div className="input-title">It will renew on {endTimeText}</div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button type="cancel" action={close}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" action={onSubmit}>
              Renew Subscription
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(RenewSubscriptionModal);
