import React, { useState } from "react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { observer } from "mobx-react";
import { SyncLoader } from "react-spinners";
import { request } from "frontend-shared/utils";
import UserStore from "src/stores/UserStore";
import AppStateStore from "src/stores/AppStateStore";
import "./EditCardDetailsModal.scss";
import { Button } from "frontend-shared/components";

function Modal({ close, cardDetails, planIsPastDue }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const closeModal = async () => {
    request.delete("/v1/users/me/setupIntent");
    close();
  };

  const onSubmit = async () => {
    if (!stripe || !elements) return;
    setLoading(true);
    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: { return_url: window.location.origin }
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message);
      setLoading(false);
    } else {
      AppStateStore?.setLoading(true);
      setTimeout(
        async () => {
          await UserStore?.fetchUser();
          AppStateStore?.setLoading(false);
          close();
        },
        planIsPastDue ? 15000 : 6500
      );
    }
  };

  const stripeErrorMessage = error ? <p className="stripe-error">{error}</p> : null;
  const submitButtonDetails = loading ? <SyncLoader size={8} color={"var(--white)"} /> : "Update card";

  return (
    <div className="edit-card-details-modal-container">
      <div className="content">
        <div className="title-container">
          <div className="title">Update Card</div>
        </div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Connected Card</div>
              <div className="input-title">
                {cardDetails?.brand?.toTitleCase()} •••• {cardDetails?.last4} Expires {cardDetails?.month?.toString().padStart(2, "0")}/
                {cardDetails?.year}
              </div>
            </div>
            <PaymentElement />
            {stripeErrorMessage}
            {planIsPastDue ? <div className="error-message">This card will be charged for your unpaid invoice.</div> : null}
          </div>
        </div>
        <div className="bottom-buttons">
          <Button type="cancel" disabled={loading} action={closeModal}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" disabled={loading} action={onSubmit}>
              {submitButtonDetails}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const EditCardDetailsModal = ({ close, secret, cardDetails, planIsPastDue }) => {
  const { stripePromise } = AppStateStore || {};
  const options = { clientSecret: secret };
  return (
    <Elements stripe={stripePromise} options={options}>
      <Modal close={close} cardDetails={cardDetails} planIsPastDue={planIsPastDue} />
    </Elements>
  );
};

export default observer(EditCardDetailsModal);
