import React, { useState } from "react";
import { observer } from "mobx-react";
import { states } from "src/constants";
import { Input, Select, Button } from "frontend-shared/components";
import { request } from "frontend-shared/utils";
import ClientsStore from "src/stores/ClientsStore";
import { PuffLoader } from "react-spinners";
import "./NewAddressModal.scss";

function NewAddressModal({ close, clientId }) {
  const [uploading, setUploading] = useState(false);

  const [street, setStreet] = useState("");
  const updateStreet = ({ target }) => setStreet(target.value);
  const [city, setCity] = useState("");
  const updateCity = ({ target }) => setCity(target.value);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState("");
  const updateZip = ({ target }) => setZip(target.value);
  const [error, setError] = useState("");
  const isValid = street && city && state && zip;

  const cancelButtonStyles = uploading ? { opacity: 0.1, pointerEvents: "none" } : {};
  const actionButtonStyles = isValid ? (uploading ? { opacity: 0.3, pointerEvents: "none" } : {}) : { opacity: 0.3, pointerEvents: "none" };

  const onSubmit = async () => {
    if (isValid && !uploading) {
      setUploading(true);
      const result = await request.post("/v1/clients/address", { body: { address: { street, city, zip, state } } });
      if (!result.lat || !result.lng) {
        setError("That address is not valid.");
        setUploading(false);
        return;
      }
      const address = { street, city, zip, state, lat: result.lat, long: result.lng };
      const client = ClientsStore?.getClientById(clientId);

      ClientsStore?.addAddress(client, address);

      close();
    }
  };

  const actionButtonContent = uploading ? <PuffLoader loading className="loader" size={24} /> : "Create Address";

  return (
    <div className="new-address-modal-container">
      <div className="content">
        <div className="title">Create New Address</div>
        <div className="error">{error}</div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Street</div>
              <Input placeholder="Street" value={street} onChange={updateStreet} />
            </div>
            <div className="input-container">
              <div className="input-title">City</div>
              <Input placeholder="City" value={city} onChange={updateCity} />
            </div>
            <div className="input-container">
              <div className="input-title">State</div>
              <Select options={states} value={state} onChange={setState} placeholder="State" />
            </div>
            <div className="input-container">
              <div className="input-title">Zip</div>
              <Input placeholder="Zip" value={zip} onChange={updateZip} />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button style={cancelButtonStyles} action={close}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" style={actionButtonStyles} action={onSubmit}>
              {actionButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(NewAddressModal);
