export const API_NAME = process.env.REACT_APP_API_NAME;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const ASSETS_URL = process.env.REACT_APP_ASSETS_BUCKET_URL;
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOW_TYPE
};
