import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import { format, formatDistanceToNowStrict, isToday } from "date-fns";
import { parsePhone } from "frontend-shared/utils";
import useModal from "src/hooks/useModal";
import NewAddressModal from "../-common/Modals/NewAddressModal";
import EditAddressModal from "../-common/Modals/EditAddressModal";
import NoteCard from "../-common/NoteCard";
import CollapsingSection from "../-common/CollapsingSection";
import NewHorseModal from "./modals/NewHorseModal";
import EditClientModal from "./modals/EditClientModal";
import { AppointmentsStore, ClientsStore, HorsesStore, UserStore } from "src/stores";
import "./ClientDetails.scss";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function HorseCard({ clientId, horseId }) {
  const navigate = useNavigate();
  const horse = HorsesStore?.horses.find(h => h.id === horseId);
  const navigateToHorse = () => navigate(`/clients/${clientId}/horse/${horseId}`);

  return (
    <div className="card" onClick={navigateToHorse}>
      <div className="image-container">
        {horse?.images?.length ? <img src={horse?.images?.[0] || ""} alt="Horse" className="image" /> : <div className="no-image" />}
        {horse?.images?.length > 1 ? <div className="more-images">{horse?.images?.length - 1} more images</div> : null}
        {!horse?.images?.length ? (
          <div className="no-image-text">
            <div>No images found</div>
          </div>
        ) : null}
      </div>
      <div className="text-container">
        <div className="name">{horse?.name}</div>
        <div className="text">
          {horse?.age} year old {horse?.color} {horse?.breed}
        </div>
        <div className="text">{horse?.notes?.length ? horse?.notes?.length + " notes" : "(no notes)"}</div>
      </div>
    </div>
  );
}

function ClientDetails() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const client = ClientsStore?.getClientById(clientId);

  const { Modal: NewAddressModalComponent, openModal: newAddress } = useModal(NewAddressModal, { clientId });
  const { Modal: NewHorseModalComponent, openModal: newHorse } = useModal(NewHorseModal, { clientId });
  const { Modal: EditClientModalComponent, openModal: editClient } = useModal(EditClientModal, { clientId });
  const { Modal: EditAddressModalComponent, openModal: openEditAddressModal } = useModal(EditAddressModal);

  const [notes, setNotes] = useState(client?.notes || []);

  useEffect(() => {
    if (!client) navigate("/");
    if (client.isArchived) navigate("/clients");
  }, [client, navigate]);

  const appointments = AppointmentsStore?.appointmentsForClient(clientId);
  const appointmentCount = appointments?.length || 0;
  const futureAppointments = appointments?.filter(a => new Date(a.start) > new Date()).length || 0;

  const updateNote = ({ id, note }) => {
    const now = new Date().valueOf();
    const index = notes.findIndex(n => n.id === id);
    const newNotes = [...notes.slice(0, index), { note, id, date: now }, ...notes.slice(index + 1)];
    setNotes(newNotes);
  };

  const saveNote = (id, note) => {
    ClientsStore.saveNote(clientId, id, note);
  };

  const deleteNote = id => {
    ClientsStore.deleteNote(clientId, id);
    const index = notes.findIndex(n => n.id === id);
    setNotes([...notes.slice(0, index), ...notes.slice(index + 1)]);
  };

  const newNote = () => {
    const id = new Date().valueOf();
    setNotes([...notes, { id, note: "New note", date: id }]);
  };

  const locations = client?.locations || [];
  const locationrows = locations.map(l => {
    const editAddress = () => openEditAddressModal({ clientId, address: l });
    return (
      <tr onClick={editAddress} key={l.street + l.city}>
        <td>
          <div className="cell-contents">{l.street}</div>
        </td>
        <td>
          <div className="cell-contents">{l.city}</div>
        </td>
        <td>
          <div className="cell-contents">{l.state}</div>
        </td>
        <td>
          <div className="cell-contents">{l.zip}</div>
        </td>
      </tr>
    );
  });

  const horses = HorsesStore?.getClientHorses(clientId).slice();
  const horseCards = horses?.map(h => <HorseCard key={h.id} clientId={clientId} horseId={h.id} />);

  const noteItems = notes
    ?.slice()
    .sort((a, b) => a.date > b.date)
    .map(n => {
      const dateTitle = isToday(n.date)
        ? format(n.date, "h:mm a")
        : capitalizeFirstLetter(formatDistanceToNowStrict(n.date), { addSuffix: true }) + " ago";
      return (
        <NoteCard key={n.id} id={n.id} date={dateTitle} note={n.note} updateNote={updateNote} saveNote={saveNote} deleteNote={deleteNote} />
      );
    });

  const termsAgreement = UserStore?.user.planType !== "BASIC" && (
    <div className="terms-agreement">
      <div>Terms Agreement</div>
      <div>{client?.pdfSignedDate ? format(new Date(client?.pdfSignedDate), "MMM do, yy 'at' hh:mm a") : "Not yet signed"}</div>
    </div>
  );

  const editClientButton = UserStore?.shouldEditsBeAllowed ? (
    <div className="contact-methods" onClick={editClient}>
      Edit Client
    </div>
  ) : null;

  return (
    <div className="client-details-container">
      <div className="header">
        <div className="name-contact-container">
          <div className="name">
            <Link to="/clients">Clients</Link>
            <BiChevronRight className="arrow" />
            <div>{client?.name}</div>
          </div>
          <div className="contact">
            <div>{parsePhone(client?.phone)}</div>
            <div>{client?.email}</div>
          </div>
        </div>
        <div className="stats">
          <div className="appointments">
            <div>
              {appointmentCount} Appointment{appointmentCount !== 1 ? "s" : ""}
            </div>
            <div>
              {futureAppointments} Future Appointment{futureAppointments.length !== 1 ? "s" : ""}
            </div>
          </div>

          <div className="appointments">
            <div>
              {horses.length} Horse{horses.length !== 1 ? "s" : ""}
            </div>
            <div>
              {locations.length} Location{locations.length !== 1 ? "s" : ""}
            </div>
          </div>
          <div style={{ flex: 1 }} />
          {termsAgreement}
          {editClientButton}
        </div>
      </div>
      <div className="tables-container">
        <CollapsingSection title="Horses" actionText="Add New" action={newHorse}>
          <div className="horse-cards">{horseCards}</div>
        </CollapsingSection>
        <CollapsingSection title="Notes" actionText="Add New" action={newNote}>
          <div className="note-row-container">{noteItems}</div>
        </CollapsingSection>
        <CollapsingSection title="Addresses" actionText="Add New" action={newAddress}>
          <table className="locations">
            <thead>
              <tr>
                <th>Street</th>
                <th>City</th>
                <th>State</th>
                <th>Zip</th>
              </tr>
            </thead>
            <tbody>{locationrows}</tbody>
          </table>
        </CollapsingSection>
      </div>
      <EditClientModalComponent />
      <NewAddressModalComponent />
      <NewHorseModalComponent />
      <EditAddressModalComponent />
    </div>
  );
}

export default observer(ClientDetails);
