import { autorun, makeAutoObservable } from "mobx";
import { format } from "date-fns";
import AuthStore from "./AuthStore";
import { request } from "frontend-shared/utils";

// https://github.com/bramus/react-native-maps-directions/blob/master/src/MapViewDirections.js (starts at line 44 - decode method)
function decodeAPIRouteResponseSteps(steps) {
  let points = [];
  for (let step of steps) {
    let encoded = step.polyline.points;
    let index = 0,
      len = encoded.length;
    let lat = 0,
      lng = 0;
    while (index < len) {
      let b,
        shift = 0,
        result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);

      let dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;
      shift = 0;
      result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      let dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;

      points.push({ latitude: lat / 1e5, longitude: lng / 1e5 });
    }
  }
  return points;
}

class RoutesStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (!AuthStore.authenticated) {
        this.clear();
      }
    });
  }

  loading = false;
  loadingRoutesForDay = {};
  rawRoutes = {};

  get routes() {
    let augmentedEntries = Object.entries(this.rawRoutes).map(([day, route]) => {
      if (!route?.overview) {
        return [day, { ...route }];
      }
      let simpleCoordinates = decodeAPIRouteResponseSteps([{ polyline: route.overview }]);
      let coordinates = route.legs.reduce((acc, next) => [...acc, ...decodeAPIRouteResponseSteps(next.steps)], []);
      let distanceInMiles = +route.distance / 1604.344;
      let properCoords = coordinates.map(({ latitude, longitude }) => ({ lat: latitude, lng: longitude }));
      return [day, { ...route, simpleCoordinates, coordinates, distanceInMiles, properCoords }];
    });
    return Object.fromEntries(augmentedEntries);
  }

  async fetchRouteForDay(day) {
    if (!day) day = format(new Date(), "yyyy-MM-dd");
    if (this.routes?.[day] != null) return;

    this.loadingRoutesForDay[day] = true;
    try {
      const { appointments, startPoint, data } = await request.get(`/v1/today/route?day=${day}`);
      console.log("data", { data });
      this.rawRoutes = { ...this.rawRoutes, [day]: { appointments, startPoint, ...data, data: data } };
    } catch (e) {
      console.warn(e);
    } finally {
      this.loadingRoutesForDay[day] = false;
    }
  }

  getRouteByDay(day) {
    if (!day) day = new Date().toISOString().split("T")[0];
    return this.routes[day] || null;
  }

  storeGasForDay(day, price) {
    if (!day) day = new Date().toISOString().split("T")[0];
    if (this.getRouteByDay(day) == null) return;
    let newDay = this.getRouteByDay(day);
    newDay.gasPrice = price;
    this.setRoutes({ ...this.rawRoutes, [day]: newDay });
  }

  setRoutes(routes) {
    this.rawRoutes = routes;
  }

  clear() {
    this.rawRoutes = {};
  }
}

export default new RoutesStore();
