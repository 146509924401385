import { useRef, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import useModal from "src/hooks/useModal";
import NewAppointmentModal from "./modals/NewAppointmentModal";
import EditAppointmentModal from "./modals/EditAppointmentModal";
import { AppointmentsStore, ClientsStore, HorsesStore, UserStore } from "src/stores";
import "./Calendar.scss";

function Calendar() {
  const [type] = useState("timeGridWeek");
  const ref = useRef(null);

  const { Modal, openModal } = useModal(NewAppointmentModal);
  const { Modal: EditModal, openModal: openEditModal } = useModal(EditAppointmentModal);

  const appointments = Object.keys(AppointmentsStore?.appointmentsByDay)
    .reduce((prev, next) => {
      return [...prev, ...AppointmentsStore?.appointmentsByDay[next]];
    }, [])
    .map(a => ({ ...a, summary: a.summary }));

  const onPress = ({ dateStr: start }) => {
    if (!UserStore?.shouldEditsBeAllowed) return;
    openModal({ start });
  };

  const onSelect = ({ startStr: start, endStr: end }) => {
    if (!UserStore?.shouldEditsBeAllowed) return;
    openModal({ start, end });
  };

  const eventChange = event => {
    return (
      <div style={{ overflow: "hidden" }}>
        <div>{event.timeText}</div>
        {event?.event?.title !== "null" ? <div>{event?.event?.title}</div> : null}
        <div>{event.event.extendedProps.summary}</div>
      </div>
    );
  };

  const eventClick = e => {
    const { start, end, title, id } = e.event;
    const { clientId, horses: horseIds, location } = e.event.extendedProps;

    const client = ClientsStore?.getClientById(clientId)?.name;
    const horses = HorsesStore?.rawHorses.filter(h => horseIds.includes(h.id)).map(h => h.name);
    openEditModal({ start, end, client, horses, location: toJS(location), title, id });
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
        initialView={type}
        nowIndicator
        eventInteractive
        height={"100%"}
        slotLabelInterval={"01:00"}
        slotDuration={"00:15:00"}
        allDaySlot={false}
        ref={ref}
        dateClick={onPress}
        eventClick={eventClick}
        select={onSelect}
        scrollTime={new Date().toISOString().split("T")[1]}
        selectable={true}
        eventContent={eventChange}
        selectConstraint={{ startTime: "07:00:00", endTime: "20:00:00" }}
        slotMinTime={"07:00:00"}
        slotMaxTime={"19:00:00"}
        events={appointments}
        headerToolbar={{ start: "prev,next", center: "title", end: "dayGridMonth,listWeek,timeGridWeek" }}
        buttonText={{ dayGridMonth: "Monthly overview", listWeek: "Weekly overview", timeGridWeek: "List" }}
      />
      <Modal />
      <EditModal />
    </div>
  );
}

export default observer(Calendar);
