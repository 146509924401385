import React, { useState } from "react";
import { Button, Input } from "frontend-shared/components";
import { observer } from "mobx-react";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import ClientsStore from "src/stores/ClientsStore";
import "./EditClientModal.scss";
import { AppStateStore, UserStore } from "src/stores";
import { useNavigate } from "react-router-dom";

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const checkRegisterEnabled = ({ email, phone }) => {
  try {
    const phoneIsValid = phone.length ? parsePhoneNumber(phone, "US").isValid() : true;
    return (email.length ? emailIsValid(email) : true) && phoneIsValid;
  } catch {
    return false;
  }
};

function EditClientModal({ close, clientId }) {
  const client = ClientsStore?.getClientById(clientId);
  const navigate = useNavigate();

  const [name, setName] = useState(client?.name || "");
  const updateName = ({ target }) => setName(target.value);

  const [phone, setPhone] = useState((client?.phone && parsePhoneNumber(client?.phone, "US").formatNational()) || "");
  const updatePhone = ({ target }) => {
    if (!isNaN(Number(phone.slice(-1)))) {
      const phoneFormatter = new AsYouType("US");
      const phone = phoneFormatter.input(target.value);
      setPhone(phone);
    } else {
      setPhone(target.value);
    }
  };
  const [email, setEmail] = useState(client?.email || "");
  const updateEmail = ({ target }) => setEmail(target.value);

  const isValid = name && checkRegisterEnabled({ email, phone });
  const actionButtonStyles = isValid ? {} : { opacity: 0.3, pointerEvents: "none" };

  const onSubmit = async () => {
    if (isValid) {
      AppStateStore?.setLoading(true);
      await ClientsStore?.updateClient({
        ...client,
        name,
        phone: phone ? parsePhoneNumber(phone, "US").format("E.164") : null,
        email
      });
      AppStateStore?.setLoading(false);

      // close();
    }
  };

  const deleteClient = async () => {
    AppStateStore?.setLoading(true);
    await ClientsStore?.deleteClient(clientId);
    navigate("/clients");
    AppStateStore?.setLoading(false);
  };

  return (
    <div className="edit-client-modal-container">
      <div className="content">
        <div className="title">Edit Client</div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Name</div>
              <Input placeholder="John Doe" value={name} onChange={updateName} />
            </div>
            <div className="input-container">
              <div className="input-title">Phone</div>
              <Input placeholder="(555) 555-1234" value={phone} onChange={updatePhone} />
            </div>
            <div className="input-container">
              <div className="input-title">Email</div>
              <Input placeholder="john@example.com" value={email} onChange={updateEmail} />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button action={close}>Cancel</Button>
          <div className="left-buttons">
            <Button className="delete-button" disabled={!UserStore?.shouldEditsBeAllowed} action={deleteClient}>
              Delete
            </Button>
            <Button type="primary" style={actionButtonStyles} action={onSubmit}>
              Edit Client
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(EditClientModal);
