import React from "react";
import { observer } from "mobx-react";
import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { BiBarChartSquare, BiCalendar, BiGroup, BiUserCircle } from "react-icons/bi";

const A = ({ children, to, ...props }) => (
  <a href={to} {...props}>
    {children}
  </a>
);

const SidebarLink = ({ currentPath, path, icon: Icon, title, external }) => {
  const LinkComponent = external ? A : Link;
  return (
    <LinkComponent className="sidebar-link-wrapper" to={path}>
      <div className={`sidebar-link${currentPath?.includes(path) ? " active" : ""}`}>
        <Icon className="link-icon" size={24} />
        <span>{title}</span>
      </div>
    </LinkComponent>
  );
};

function Sidebar() {
  const { pathname } = useLocation();

  return (
    <div className="sidebar">
      <div className="logo-section">
        {/* <div className="logo">LOGO</div> */}
        <div className="logo">
          <img className="logo-icon" src={process.env.PUBLIC_URL + "/logo192.png"} alt="site logo" />
          <div className="logo-text">
            Farrier<span className="plus">Plus</span>
          </div>
        </div>
      </div>
      <div style={{ borderBottomWidth: 1, width: "100%", borderBottomColor: "black" }} />
      <div className="links-section">
        <SidebarLink path="/dashboard" title="Dashboard" currentPath={pathname} icon={BiBarChartSquare} />
        <SidebarLink path="/clients" title="Clients" currentPath={pathname} icon={BiGroup} />
        <SidebarLink path="/calendar" title="Calendar" currentPath={pathname} icon={BiCalendar} />
        <SidebarLink path="/account" title="Account" currentPath={pathname} icon={BiUserCircle} />
      </div>
      <div className="flexer" />
    </div>
  );
}

export default observer(Sidebar);
