import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import "./Dashboard.scss";

import { GoogleMap, withGoogleMap, withScriptjs, Marker, Polyline, InfoWindow } from "react-google-maps";
import { AppointmentsStore, ClientsStore, RoutesStore, UserStore } from "src/stores";
import { format, formatDistanceStrict } from "date-fns";
import { toJS } from "mobx";
import useGasPrices from "src/hooks/useGasPrices";
import { SyncLoader } from "react-spinners";
import { BiGasPump, BiStats } from "react-icons/bi";

//https://dribbble.com/shots/15197323-Home-finances-dashboard

const mapInteractables = [
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape.natural",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  }
];

const Map = withScriptjs(
  withGoogleMap(
    observer(props => {
      const date = format(new Date(), "yyyy-MM-dd");

      const [markersVisible, setMarkersVisible] = useState(true);
      const [activeMarkers, setActiveMarkers] = useState(["start"]);

      const mapRef = useRef(null);

      const loadingRoute = RoutesStore.loadingRoutesForDay?.[date];
      const mapRoute = props.route || {}; //eslint-disable-line

      const startPoint = toJS(mapRoute?.startPoint) || []; //eslint-disable-line
      const coordinates = mapRoute?.coordinates || []; //eslint-disable-line
      const locations = toJS(mapRoute?.locations)?.slice(1) || []; //eslint-disable-line

      const zoomChanged = () => {
        setMarkersVisible(mapRef.current.getZoom() >= 10);
      };

      useEffect(() => {
        RoutesStore.fetchRouteForDay(date);
      }, [date]);

      useEffect(() => {
        const coords = toJS(coordinates);
        if (!coords.length) return;
        const latitudes = coords.map(a => a.latitude);
        const longitudes = coords.map(a => a.longitude);
        let north = Math.max(...latitudes);
        let south = Math.min(...latitudes);
        let east = Math.max(...longitudes);
        let west = Math.min(...longitudes);
        mapRef.current.fitBounds({ north, south, east, west });
      }, [coordinates, mapRef]);

      return (
        <GoogleMap
          ref={mapRef}
          resetBoundsOnResize
          defaultZoom={12}
          clickableIcons={false}
          defaultCenter={props?.defaultCenter?.lat ? props?.defaultCenter : { lat: 40.7608, lng: -111.891 }}
          onZoomChanged={zoomChanged}
          options={{ streetViewControl: false, fullscreenControl: false, clickableIcons: false, styles: mapInteractables }}
        >
          {loadingRoute ? null : startPoint?.latitude ? (
            <Marker
              visible={markersVisible}
              opacity={0.75}
              position={{ lat: startPoint.latitude, lng: startPoint.longitude }}
              onClick={() => setActiveMarkers([...activeMarkers, "start"])}
            >
              {markersVisible && activeMarkers.includes("start") ? (
                <InfoWindow onCloseClick={() => setActiveMarkers([...activeMarkers.filter(a => a !== "start")])}>
                  <div>Starting point</div>
                </InfoWindow>
              ) : null}
            </Marker>
          ) : null}

          {loadingRoute
            ? null
            : locations.length
            ? locations.map((loc, index) => {
                return (
                  <Marker
                    visible={markersVisible}
                    opacity={0.75}
                    position={{ lat: loc.latitude, lng: loc.longitude }}
                    onClick={() => setActiveMarkers([...activeMarkers, index])}
                  >
                    {markersVisible && activeMarkers.includes(index) ? (
                      <InfoWindow onCloseClick={() => setActiveMarkers([...activeMarkers.filter(a => a !== index)])}>
                        <div>Stop {index + 1}</div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              })
            : null}

          {loadingRoute ? null : mapRoute?.properCoords?.length ? (
            <Polyline path={mapRoute?.properCoords} options={{ strokeColor: "#09bc8a", strokeWeight: 3 }} />
          ) : null}
        </GoogleMap>
      );
    })
  )
);

function Dashboard() {
  const date = format(new Date(), "yyyy-MM-dd");
  const route = RoutesStore?.routes?.[date] || [];

  const myAddress = UserStore?.user?.address || null;
  const routeIsLoading = RoutesStore?.loadingRoutesForDay?.[date];
  const gasPrice = useGasPrices(route?.distanceInMiles || 0, date);

  const futureAppointments = AppointmentsStore?.appointments?.filter(a => new Date(a.start) > new Date()) || [];
  let appointmentItems = futureAppointments?.map(a => {
    const clientName = ClientsStore?.getClientById(a.clientId)?.name || "";
    const date = format(new Date(a.start), "eee, LLL do");
    const start = format(new Date(a.start), "h:mm a");
    const duration = formatDistanceStrict(new Date(a.start), new Date(a.end));
    return (
      <div className="appointment">
        <div className="client">{clientName}</div>
        <div className="date">{date}</div>
        <div className="time">
          {start} for {duration}
        </div>
        <div className="horses">
          {a.horses?.length || "No"} Horse{a.horses?.length === 1 ? "" : "s"}
        </div>
      </div>
    );
  });

  appointmentItems =
    appointmentItems.length === 0 ? (
      <div className="appointment">
        <div className="client">No Upcoming Appointments</div>
      </div>
    ) : (
      appointmentItems
    );

  return (
    <div className="dashboard-container">
      <div className="header">
        <div className="name">
          <div>Dashboard</div>
        </div>
      </div>
      <div className="content">
        <div className="map">
          <div className="map-header">
            {routeIsLoading ? (
              <SyncLoader size={8} style={{ padding: "12px" }} />
            ) : !route?.locations?.length ? (
              <div className="details-container">
                <div className="text">No appointments scheduled for today.</div>
              </div>
            ) : (
              <>
                <div className="details-container">
                  <BiStats className="icon" size={24} />
                  <div className="text">
                    {routeIsLoading ? (
                      <SyncLoader size={6} />
                    ) : (
                      `${route?.locations?.length - 1} Stop${route?.locations?.length === 2 ? "" : "s"}`
                    )}
                  </div>
                </div>
                <div className="details-container">
                  <BiGasPump className="icon" size={24} />
                  <div className="text">{!gasPrice || routeIsLoading ? <SyncLoader size={6} /> : `$${gasPrice}`}</div>
                </div>
              </>
            )}
          </div>
          <div className="map-container">
            <Map
              route={route}
              defaultCenter={{ lat: myAddress?.latitude || null, lng: myAddress?.longitude || null }}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBJfMHZRTMX52wEeorJb5E1UQ3xKfXWWvc&v=3.exp" //TODO: FIX THIS KEY!
              loadingElement={<div style={{ height: `100%`, width: "100%" }} />}
              containerElement={<div style={{ height: `100%`, width: "100%" }} />}
              mapElement={<div style={{ height: `100%`, width: "100%" }} />}
              resetBoundsOnResize
            />
          </div>
        </div>
      </div>
      <div className="upcoming-appointments">
        <div className="appointments-header">Upcoming Appointments</div>
        {appointmentItems}
      </div>
    </div>
  );
}

export default observer(Dashboard);
