import { autorun, makeAutoObservable } from "mobx";
import groupBy from "lodash.groupby";
import { request } from "frontend-shared/utils";
import AuthStore from "./AuthStore";
import AppointmentsStore from "./AppointmentsStore";

class HorsesStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore.authenticated) {
        this.initialize();
      } else {
        this.clear();
      }
    });
  }

  async initialize() {
    this.fetchHorses();
  }

  rawHorses = [];
  loading = false;

  get horses() {
    return this.rawHorses.filter(h => !h.isArchived);
  }

  // async uploadImage(image, uploadURL) {
  //   //black magic trickery
  //   const fetchedFile = await fetch(image);
  //   const fileBlob = await fetchedFile.blob();
  //   //Convert stuff
  //   const base64 = await FileSystem.readAsStringAsync(image, {
  //     encoding: FileSystem.EncodingType.Base64
  //   });
  //   const buffer = Buffer.from(base64, "base64");
  //   await axios.put(uploadURL, buffer, { headers: { "Content-Type": fileBlob["type"] || "image/jpeg" } });
  // }

  async addHorse(horse) {
    try {
      const { newHorse } = await request.post("/v1/horses/", {
        body: horse
      });

      // let uploads = [];
      // for (let index in newHorse.images) {
      //   const assetURL = newHorse.images[index];
      //   const localURI = horse.images[index];
      //   const uploadURL = uploadURLs[assetURL];
      //   uploads.push(this.uploadImage(localURI, uploadURL));
      // }
      this.rawHorses = [...this.rawHorses, newHorse];
    } catch (e) {
      console.warn(e);
    }
  }

  async updateHorse(horse) {
    const localHorse = this.horses.find(c => c.id === horse.id);

    try {
      if (!horse.notes && localHorse.notes) horse.notes = localHorse.notes;
      if (!horse.images && localHorse.images) horse.images = localHorse.images;

      this.rawHorses = [...this.rawHorses?.filter(h => h.id !== horse.id), horse];
      const { updatedHorse, uploadURLs } = await request.put("/v1/horses/", {
        body: { horse }
      });

      let uploads = [];
      for (let index in updatedHorse.images) {
        const assetURL = updatedHorse.images[index];
        const localURI = horse.images[index];
        if (!localURI.startsWith("file://")) {
          continue;
        }
        const uploadURL = uploadURLs[assetURL];
        uploads.push(this.uploadImage(localURI, uploadURL));
      }
      await Promise.all(uploads);

      this.rawHorses = [...this.rawHorses.filter(a => a.id !== updatedHorse.id), updatedHorse];
    } catch (err) {
      console.warn(err);
    }
  }

  setHorses(horses) {
    this.rawHorses = horses;
  }

  getArchivedHorsesByClient() {
    const groupedHorses = groupBy(this.rawHorses, h => h.clientId);
    return groupedHorses || [];
  }

  getHorsesByClient() {
    const groupedHorses = groupBy(this.horses, h => h.clientId);
    return groupedHorses || [];
  }

  getClientHorses(clientId) {
    return this.horses.filter(h => h.clientId === clientId);
  }

  async saveNote(clientId, horseId, noteId, note) {
    const horse = this.horses.find(h => h.id === horseId);
    const horseNotes = horse?.notes?.filter(n => n.id !== noteId) || [];
    const thisNote = { note: note, id: noteId, date: new Date().valueOf() };
    await this.updateHorse({
      id: horseId,
      clientId,
      notes: [...horseNotes, thisNote]
    });
  }

  async deleteNote(clientId, horseId, noteId) {
    const horse = this.horses.find(h => h.id === horseId);
    if (!horse?.notes?.find(n => n.id === noteId)) return;
    const horseNotes = horse?.notes?.filter(n => n.id !== noteId) || [];
    this.rawHorses = [...this.rawHorses?.filter(h => h.id !== horseId), { ...horse, notes: horseNotes }];
    await this.updateHorse(
      {
        id: horseId,
        clientId: clientId,
        notes: [...horseNotes]
      },
      true
    );
  }

  async fetchHorses() {
    try {
      this.loading = true;
      const result = await request.get("/v1/horses/");
      this.setHorses(result);
    } catch (err) {
      console.warn("Fetching horses: ", err);
    } finally {
      this.loading = false;
    }
  }

  async deleteHorse(id) {
    const originalHorse = this.horses.find(h => h.id === id);

    if (AppointmentsStore?.appointmentsForClient(originalHorse.clientId).find(a => a.horses?.includes(id))) {
      console.log("Arvhicing");
      try {
        await this.updateHorse({ ...originalHorse, isArchived: true });
      } catch (err) {
        console.warn("Failed to archive horse");
      }
    } else {
      try {
        await request.delete("/v1/horses/", { body: { id } });
        this.rawHorses = [...this.rawHorses.filter(h => h.id !== id)];
      } catch (err) {
        this.rawHorses = [...this.rawHorses, originalHorse];
      }
    }
  }

  clear() {
    this.setHorses([]);
  }
}

export default new HorsesStore();
