import { autorun, makeAutoObservable } from "mobx";
import { IS_DEV } from "../constants";
import { request } from "frontend-shared/utils";
import AuthStore from "./AuthStore";
import HorsesStore from "./HorsesStore";
import AppointmentsStore from "./AppointmentsStore";

const checkForSameAddress = (address1, address2) => {
  return (
    address1?.street === address2?.street &&
    address1?.city === address2?.city &&
    address1?.state === address2?.state &&
    address1?.zip === address2?.zip
  );
};

class ClientsStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore.authenticated) {
        this.initialize();
      } else {
        this.clear();
      }
    });
  }

  async initialize() {
    this.fetchClients();
  }

  rawClients = [];
  loading = false;

  get clients() {
    return this.rawClients.filter(c => !c.isArchived);
  }

  get clientsForPicker() {
    return this.clients?.map(c => c.name);
  }

  get getArchivedClientsWithHorses() {
    let horsesByClient = HorsesStore.getArchivedHorsesByClient();
    let clients = this.rawClients.map(c => ({ ...c, horses: horsesByClient?.[c.id] || [] }));
    return clients;
  }

  get getClientsWithHorses() {
    let horsesByClient = HorsesStore.getHorsesByClient();
    let clients = this.clients.map(c => ({ ...c, horses: horsesByClient?.[c.id] || [] }));
    return clients;
  }

  getClientById(id) {
    return this.rawClients.find(c => c.id === id);
  }

  async addClient(client) {
    const { name } = client;
    if (!name) return;
    try {
      const result = await request.post("/v1/clients/", { body: { locations: [], name } });
      const clientId = result.id;
      this.rawClients = [...this.rawClients, result];
      return clientId;
    } catch (err) {
      console.warn(err);
    }
  }

  async updateClient(client) {
    const initialClient = this.clients.find(c => c.id === client.id);
    const { firstAppointmentDate, mostRecentDate, upcomingAppointments, horses, ...updatedClient } = client;
    try {
      this.rawClients = [...this.rawClients.filter(c => c.id !== client.id), client];
      const result = await request.put("/v1/clients/", { body: { client: { ...updatedClient } } });
      this.rawClients = [...this.rawClients.filter(c => c.id !== client.id), result];
    } catch (err) {
      console.warn("Failed to update client", err);
      this.rawClients = [...this.rawClients.filter(c => c.id !== client.id), initialClient];
    }
  }

  async addAddress(client, address) {
    const initialClient = this.clients.find(c => c.id === client.id);
    let { firstAppointmentDate, mostRecentDate, upcomingAppointments, horses, ...updatedClient } = client;
    updatedClient.locations = [...(updatedClient?.locations || []), address];
    try {
      this.rawClients = [...this.rawClients.filter(c => c.id !== client.id), client];
      const result = await request.put("/v1/clients/", { body: { client: { ...updatedClient } } });
      this.rawClients = [...this.rawClients.filter(c => c.id !== client.id), result];
    } catch (err) {
      console.warn("Failed to update client", err);
      this.rawClients = [...this.rawClients.filter(c => c.id !== client.id), initialClient];
    }
  }

  async deleteAddress(clientId, address) {
    const client = this.clients.find(c => c.id === clientId);
    const clientLocations = client?.locations?.filter(a => !checkForSameAddress(a, address)) || [];
    this.rawClients = [...this.rawClients?.filter(c => c.id !== clientId), { ...client, locations: clientLocations }];
    await this.updateClient({
      id: clientId,
      locations: [...clientLocations]
    });
  }

  async saveNote(clientId, noteId, note) {
    const client = this.clients.find(c => c.id === clientId);
    const clientNotes = client?.notes?.filter(n => n.id !== noteId) || [];
    const thisNote = { note: note, id: noteId, date: new Date().valueOf() };
    await this.updateClient(
      {
        id: clientId,
        notes: [...clientNotes, thisNote]
      },
      true
    );
  }

  async deleteNote(clientId, noteId) {
    const client = this.clients.find(c => c.id === clientId);
    if (!client?.notes?.find(n => n.id === noteId)) return;
    const clientNotes = client?.notes?.filter(n => n.id !== noteId) || [];
    this.rawClients = [...this.rawClients?.filter(c => c.id !== clientId), { ...client, notes: clientNotes }];
    await this.updateClient(
      {
        id: clientId,
        notes: [...clientNotes]
      },
      true
    );
  }

  async deleteClient(clientId) {
    const originalClient = this.getClientById(clientId);

    const appointments = AppointmentsStore?.appointmentsForClient(clientId) || [];
    const horses = HorsesStore?.getArchivedHorsesByClient(originalClient) || [];
    if (appointments?.length || horses?.length) {
      //archive
      await this.updateClient({ ...originalClient, isArchived: true });
      if (horses?.length) {
        await Promise.all(horses?.filter(h => !h.isArchived)?.map(h => HorsesStore?.updateHorse({ ...h, isArchived: true })));
      }
    } else {
      //delete
      try {
        this.rawClients = this.rawClients.filter(c => c.id !== clientId);
        await request.delete(`/v1/clients/${clientId}`);
      } catch (err) {
        console.warn("Failed to delete client");
      }
    }
  }

  async deleteAllClients() {
    try {
      await request.delete("/v1/clients/all");
      this.setClients([]);
    } catch (err) {
      if (IS_DEV) console.warn("Deleting all clients: ", err);
    }
  }

  async setClients(clients) {
    this.rawClients = clients;
  }

  async fetchClients() {
    try {
      this.loading = true;
      const result = await request.get("/v1/clients/");
      this.setClients(result);
    } catch (err) {
      console.error(err);
      if (IS_DEV) console.warn("Fetching clients: ", err);
    } finally {
      this.loading = false;
    }
  }

  clear() {
    this.rawClients = [];
  }
}

export default new ClientsStore();
