import { request } from "frontend-shared/utils";
import haversine from "haversine";
import { UserStore } from "../stores";

export async function getGasCost(address) {
  await Location.requestForegroundPermissionsAsync();

  let myZip;
  let currentLocation;
  currentLocation = UserStore.user.address;
  if (!currentLocation) return 0;
  currentLocation.latitude = currentLocation.lat;
  currentLocation.longitude = currentLocation.long;
  myZip = UserStore.user.address.zip;
  const distance = haversine(currentLocation, { latitude: address.lat, longitude: address.long }, { unit: "mile" });
  try {
    const body = {
      operationName: "LocationBySearchTerm",
      variables: {
        fuel: 1,
        maxAge: 0,
        search: myZip
      },
      query:
        "query LocationBySearchTerm($lat: Float, $lng: Float, $search: String) {  locationBySearchTerm(lat: $lat, lng: $lng, search: $search) { countryCode displayName latitude longitude regionCode trends { areaName country today todayLow trend}}}"
    };

    const { data } = await request.unauthenticated.post("https://www.gasbuddy.com/graphql", body);
    const gasPricePerGallon = data.locationBySearchTerm.trends[0].today;
    const mpg = UserStore.user.mpg || 25;
    const priceToDrive = (distance / mpg) * gasPricePerGallon;
    return priceToDrive.toFixed(2);
  } catch (e) {
    console.warn(e);
  }
}

export async function getGasCostFromDistance(distance) {
  let currentLocation = UserStore?.user?.address || {};
  if (!currentLocation) return 0;
  currentLocation.latitude = currentLocation.lat;
  currentLocation.longitude = currentLocation.long;
  let myZip = UserStore.user.address.zip;
  try {
    const body = {
      operationName: "LocationBySearchTerm",
      variables: {
        fuel: 1,
        maxAge: 0,
        search: myZip
      },
      query:
        "query LocationBySearchTerm($lat: Float, $lng: Float, $search: String) {  locationBySearchTerm(lat: $lat, lng: $lng, search: $search) { countryCode displayName latitude longitude regionCode trends { areaName country today todayLow trend}}}"
    };

    const { data } = await request.unauthenticated.post("https://www.gasbuddy.com/graphql", body);
    const gasPricePerGallon = data.locationBySearchTerm.trends[0].today;
    const mpg = UserStore.user.mpg || 25;
    const priceToDrive = (distance / mpg) * gasPricePerGallon;
    return priceToDrive.toFixed(2);
  } catch (e) {
    console.warn(e);
  }
}
