import React, { useEffect, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import ReactDatePicker from "react-datepicker";
import { roundToNearestMinutes, setHours, setMinutes } from "date-fns";
import { MultiSelect, Select, Button, TextArea } from "frontend-shared/components";
import { AppointmentsStore, ClientsStore } from "src/stores";
import "./NewAppointmentModal.scss";
import "react-datepicker/dist/react-datepicker.css";

function NewAppointmentModal({ close, start, end }) {
  const clients = ClientsStore?.clientsForPicker;
  const [selectedClient, setSelectedClient] = useState(null);
  const horses = selectedClient ? ClientsStore.getClientsWithHorses.find(c => c.name === selectedClient)?.horses : [];
  const [selectedHorses, setSelectedHorses] = useState([]);

  const locations = selectedClient ? toJS(ClientsStore.clients.find(c => c.name === selectedClient).locations) : [];

  const [location, setLocation] = useState(null);
  const updateLocation = street => setLocation(locations.find(l => l.street === street));
  const [startTime, setStartTime] = useState(roundToNearestMinutes(new Date(start || null), { nearestTo: 15 }));
  const [uploading, setUploading] = useState(false);

  const [details, setDetails] = useState("");

  useEffect(() => {
    setSelectedHorses([]);
    setLocation({});
  }, [selectedClient]);

  const updateStartTime = time => {
    setStartTime(time);
    if (startTime > endTime) {
      setEndTime(time);
    } else {
      setEndTime(new Date(time.toISOString().split("T")[0] + "T" + endTime.toISOString().split("T")[1]));
    }
  };

  const [endTime, setEndTime] = useState(end ? new Date(end) : new Date(startTime));

  const filterTimes = time => new Date(time) > new Date();
  const filterTimesForEnd = time => new Date(time) > new Date(startTime);

  const minTime = setMinutes(setHours(new Date(), 7), 0);
  const maxTime = setMinutes(setHours(new Date(), 19), 0);
  const minDate = new Date();

  const createAppointment = async () => {
    if (uploading) return;
    if (!uploadEnabled) return;
    setUploading(true);
    const horsesForAppointment = horses.filter(h => selectedHorses.includes(h.name)).map(h => h.id);
    const clientId = ClientsStore.clients.find(c => c.name === selectedClient).id;
    //title, location, horses, clientId, start, end
    await AppointmentsStore.addAppointment({
      title: details,
      location,
      horses: horsesForAppointment,
      clientId,
      start: startTime.toISOString(),
      end: endTime.toISOString()
    });
    close();
  };

  const uploadEnabled = startTime && endTime && startTime.toISOString() !== endTime.toISOString() && location?.street && selectedClient;

  const cancelButtonStyles = uploading ? { opacity: 0.1, pointerEvents: "none" } : {};
  const actionButtonStyles = uploadEnabled
    ? uploading
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };

  const actionButtonContent = uploading ? "UPLOADING" : "Create Appointment";

  return (
    <div className="new-appointment-modal-container">
      <div className="content">
        <div className="title">Create Appointment</div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-container">
              <div className="input-title">Client</div>
              <Select options={clients} value={selectedClient} onChange={setSelectedClient} isSearchable placeholder="Select client" />
            </div>
            <div className="input-container">
              <div className="input-title">Horses</div>
              <MultiSelect
                options={horses?.map(h => h.name) || []}
                value={selectedHorses}
                onChange={setSelectedHorses}
                isSearchable
                placeholder="Select horses"
              />
            </div>

            <div className="input-container multi">
              <div className="row left">
                <div className="input-title">Start time</div>
                <ReactDatePicker
                  selected={startTime}
                  showTimeSelect
                  // dateFormat="dd/mm/yyyy h:mm aa"
                  minTime={minTime}
                  maxTime={maxTime}
                  filterTime={filterTimes}
                  onChange={updateStartTime}
                  dateFormat={"E, LLL do 'at' h:mm a"}
                  timeIntervals={15}
                  timeFormat={"p"}
                  minDate={minDate}
                  className="datetime-picker"
                />
                {/* <Select options={clients} value={selectedClient} onChange={setSelectedClient} isSearchable placeholder="Select horses" /> */}
              </div>
              <div className="row right">
                <div className="input-title">End time</div>
                <ReactDatePicker
                  selected={endTime}
                  minTime={minTime}
                  maxTime={maxTime}
                  dateFormat="E, LLL do 'at' h:mm a"
                  filterTime={filterTimesForEnd}
                  onChange={setEndTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeFormat={"p"}
                  className="datetime-picker"
                />
              </div>
            </div>

            <div className="input-container">
              <div className="input-title">Location</div>
              <Select
                options={locations?.map(l => l.street)}
                value={location?.street || null}
                onChange={updateLocation}
                isSearchable
                placeholder="Select Location"
              />
            </div>
            <div className="input-container text-area">
              <div className="input-title">Details</div>
              <TextArea
                height={96}
                value={details}
                maxCharacters={280}
                onChange={t => setDetails(t.target.value)}
                placeholder="Details..."
              />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button style={cancelButtonStyles} action={close}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" style={actionButtonStyles} action={createAppointment}>
              {actionButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(NewAppointmentModal);
