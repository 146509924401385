import { useEffect, useState } from "react";
import { RoutesStore } from "src/stores";
import { getGasCostFromDistance } from "src/utils/getGasCost";

export default function useGasPrices(distance, day) {
  const [gasPrice, setGasPrice] = useState(0);
  const [hasDayPrice, setHasDayPrice] = useState(false);

  if (day) {
    const route = RoutesStore.getRouteByDay(day);
    if (!route?.gasPrice) {
      (async () => {
        const price = await getGasCostFromDistance(distance);
        setGasPrice(price);
        RoutesStore.storeGasForDay(day, price);
        setHasDayPrice(true);
      })();
    }
  }

  useEffect(() => {
    (async () => {
      if (!hasDayPrice) {
        setGasPrice(await getGasCostFromDistance(distance));
      }
    })();
  }, [distance, hasDayPrice]);

  return gasPrice;
}
