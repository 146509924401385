import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { format, formatDistanceToNowStrict, isToday } from "date-fns";
import HorsesStore from "src/stores/HorsesStore";
import NoteCard from "../-common/NoteCard";
import "./HorseDetails.scss";
import CollapsingSection from "../-common/CollapsingSection";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import ClientsStore from "src/stores/ClientsStore";
import { UserStore } from "src/stores";
import useModal from "src/hooks/useModal";
import EditHorseModal from "./modals/EditHorseModal";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function HorseDetails() {
  const { clientId, horseId } = useParams();

  const clientName = ClientsStore?.getClientById(clientId)?.name || "";
  const horse = HorsesStore?.horses.find(h => h.id === horseId) || {}; //eslint-disable-line

  const { Modal: EditHorseModalComponent, openModal: editHorse } = useModal(EditHorseModal, {
    clientId,
    horseName: horse.name,
    horseAge: horse.age,
    horseBreed: horse.breed,
    horseColor: horse.color,
    id: horseId
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!horse?.name) {
      navigate("/");
    }
  }, [horse, navigate]);

  const [notes, setNotes] = useState(horse?.notes || []);
  const updateNote = ({ id, note }) => {
    const now = new Date().valueOf();
    const index = notes.findIndex(n => n.id === id);
    const newNotes = [...notes.slice(0, index), { note, id, date: now }, ...notes.slice(index + 1)];
    setNotes(newNotes);
  };

  const saveNote = (id, note) => {
    HorsesStore.saveNote(clientId, horseId, id, note);
  };

  const deleteNote = id => {
    HorsesStore.deleteNote(clientId, horseId, id);
    const index = notes.findIndex(n => n.id === id);
    setNotes([...notes.slice(0, index), ...notes.slice(index + 1)]);
  };

  const newNote = () => {
    const id = new Date().valueOf();
    setNotes([...notes, { id, note: "New note", date: id }]);
  };

  const noteItems = notes
    ?.slice()
    .sort((a, b) => a.date > b.date)
    .map(n => {
      const dateTitle = isToday(n.date)
        ? format(n.date, "h:mm a")
        : capitalizeFirstLetter(formatDistanceToNowStrict(n.date), { addSuffix: true }) + " ago";
      return (
        <NoteCard key={n.id} id={n.id} date={dateTitle} note={n.note} updateNote={updateNote} saveNote={saveNote} deleteNote={deleteNote} />
      );
    });

  const editHorseButton = UserStore?.shouldEditsBeAllowed ? (
    <div className="action-text" onClick={editHorse}>
      Edit Horse
    </div>
  ) : null;

  return (
    <div className="horse-details-container">
      <div className="header">
        <div className="name-contact-container">
          <div className="name">
            <Link to="/clients">Clients</Link>
            <BiChevronRight className="arrow" />
            <Link to={`/clients/${clientId}`}>{clientName}</Link>
            <BiChevronRight className="arrow" />
            <div>{horse?.name}</div>
          </div>
        </div>
        <div className="stats">
          <div className="appointments">
            <div>
              {horse?.age} year old {horse?.color} {horse?.breed}
            </div>
          </div>
          {editHorseButton}
        </div>
      </div>

      <CollapsingSection title="Notes" actionText={"Add New"} action={newNote}>
        <div className="note-row-container">{noteItems}</div>
      </CollapsingSection>
      <CollapsingSection title="Images">
        <div className="note-row-container">
          {horse?.images?.map(i => {
            return <img key={i} className="image" src={i} alt="Horse" />;
          })}
        </div>
      </CollapsingSection>
      <EditHorseModalComponent />
    </div>
  );
}

export default observer(HorseDetails);
