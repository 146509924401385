import groupBy from "lodash.groupby";
import { autorun, makeAutoObservable } from "mobx";
import AuthStore from "./AuthStore";
import ClientsStore from "./ClientsStore";
import { request } from "frontend-shared/utils";

class AppointmentsStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore.authenticated) {
        this.initialize();
      } else {
        this.clear();
      }
    });
  }

  async initialize() {
    // this.setAppointments(mockEvents);
    this.fetchAppointments();
  }

  appointments = [];
  loading = false;

  async addAppointment(appointment) {
    try {
      await request.post("/v1/appointments/", { body: { ...appointment } });
      this.appointments = [...this.appointments, appointment];
    } catch (err) {
      console.warn(err);
    }
  }

  async updateAppointment(appointment) {
    let existingAppointments = this.appointments.slice();
    try {
      this.appointments = [...this.appointments.filter(a => a.id !== appointment.id), appointment];
      const result = await request.put(`/v1/appointments/${appointment.id}`, { body: { updates: appointment } });
      this.appointments = [...this.appointments.filter(a => a.id !== appointment.id), result];
    } catch (e) {
      this.appointments = existingAppointments;
    }
  }

  get appointmentsByDay() {
    const appointmentsWithSummary = this.appointments?.map(({ ...result }) => {
      const clientName = ClientsStore.clients.find(c => c.id === result.clientId)?.name || "";
      return {
        ...result,
        horses: result.horses || [],
        color: "#b9b2c2",
        display: "block",
        summary: `${result?.horses?.length || "No"} horse${result?.horses?.length !== 1 ? "s" : ""} for ${clientName} at ${
          result.location.street
        }, ${result.location.city}`
      };
    });
    return groupBy(appointmentsWithSummary, e => e.start.split("T")[0]);
  }

  appointmentsForClient(cliendId) {
    return this.getAppointments().filter(a => a.clientId === cliendId);
  }

  setAppointments(appointments) {
    this.appointments = appointments;
  }

  getAppointments() {
    const appointmentsWithSummary = this.appointments.map(({ ...result }) => {
      const clientName = ClientsStore.clients.find(c => c.id === result.clientId)?.name || "";
      return {
        ...result,
        horses: result.horses || [],
        summary: `${result?.horses?.length || 0} horse${result?.horses?.length > 1 ? "s" : ""} for ${clientName} at ${
          result.location.street
        }, ${result.location.city}`
      };
    });
    return appointmentsWithSummary;
  }

  async fetchAppointments() {
    try {
      this.loading = true;
      const result = await request.get("/v1/appointments/");
      this.setAppointments(result);
    } catch (err) {
      console.warn("Fetching clients: ", err);
    } finally {
      this.loading = false;
    }
  }

  async deleteAppointment(id) {
    const originalAppointments = this.appointments.slice();
    try {
      this.appointments = [...this.appointments.filter(a => a.id !== id)];
      request.delete(`/v1/appointments/${id}`);
    } catch (err) {
      this.appointments = originalAppointments;
    }
  }

  clear() {
    this.setAppointments([]);
  }
}

export default new AppointmentsStore();
