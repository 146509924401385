import { loadStripe } from "@stripe/stripe-js";
import { request } from "frontend-shared/utils";
import { makeAutoObservable } from "mobx";

const STRIPE_PUBLISHABLE_KEY =
  "pk_test_51LLC3wEGjWuZ23pUZSuieKyx52tacUnlbIEHkzWoUn5oGJPwnquxvWZahMGsT6B7up4XEjK7H3R9zBgM7HCN2E8c00SJYmAUMo";

class AppStateStore {
  constructor() {
    makeAutoObservable(this);
    this.initializeStripe();
  }

  loading = false;

  async initializeStripe() {
    try {
      this.stripeLoading = true;
      this.stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
      await this.stripePromise;
      this.stripeLoading = false;
    } catch (err) {
      console.warn(err);
      this.stripeLoading = false;
    }
  }

  async fetchSetupIntentSecret() {
    // this.loading = true;
    const info = await request.get("/v1/users/me/newCardIntent");
    // this.loading = false;
    return info.secret;
  }

  setLoading(loading) {
    this.loading = loading;
  }
}

export default new AppStateStore();
